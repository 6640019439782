.CodeMirror {
  @include cli-medium;
  @include add-color-mix(color, $primary-neutral, $black 40%);
  width: 100%;

  span {
    @include cli-medium;
  }

  &-placeholder {
    color: $primary-neutral !important;
  }

  &-code {
    div {
      &:hover,
      &:active {
        @include add-color-mix(background-color, $neutral-blue, $white 95%);
      }
    }
  }

  &-line {
    .cm {
      &-string {
        @include add-color-mix(color, $primary-pink, $black 30%);
      }

      &-keyword {
        @include add-color-mix(color, $secondary-purple, $black 20%);
      }

      &-comment {
        @include add-color-mix(color, $primary-orange, $black 20%);
      }

      &-number {
        @include add-color-mix(color, $primary-cyan, $black 20%);
      }

      &-atoms {
        @include add-color-mix(color, $primary-blue, $black 20%);
      }

      &-bracket {
        @include add-color-mix(color, $neutral-orange, $black 20%);
      }

      &-type {
        @include add-color-mix(color, $primary-green, $black 30%);
      }
    }
  }

  &-gutter {
    &-elt {
      @include add-color-mix(color, $neutral-purple, $black 40%);
      text-align: center;
    }
  }
}
