.manage-landing {
  height: 100%;
  overflow: hidden;

  .manage-landing-sidenav {
    background: $brand-color-light;
    width: 4rem;

    .manage-landing-content {
      height: 100%;
    }
  }

  .manage-landing-page-content {
    flex: 0 0 calc(100% - 4rem);
    width: calc(100% - 4rem);

    .router-outlet {
      flex: 1 1 auto;
      width: 100%;
    }

    app-manage-welcome-layout {
      display: flex;
      flex: 1;
    }

    app-manage-analyses-layout {
      display: flex;
      flex: 1;
    }

    app-polly-manage-workspace-layout {
      display: flex;
      flex: 1;
    }

    app-workspace-card-view {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    app-polly-discover-home {
      display: flex;
      flex: 1;
    }

    app-polly-data-metrics-home {
      display: flex;
      flex: 1;
    }

    app-ingestion-details-home,
    app-ingestion-run-home,
    app-audit-home,
    app-omixatlas-home {
      display: flex;
      flex: 1;
      width: 100%;
    }

    app-omixatlas-explorer {
      display: block;
      height: 100%;
    }

    app-audit-list {
      display: block;
      height: 100%;
    }
  }
}

.home-container {
  background: lighten($brand-color, 25%);
}

.collapsible-sideNav {
  background-color: $brand-color-light;
}
