html {
  overflow: hidden;
}

%heading-title {
  color: $orange !important;
  font-size: $paragraph-font-size-xs !important;
  font-weight: $font-weight-semi-bold !important;
  line-height: normal !important;
  padding-top: 10px !important;
}

%listing {
  ul {
    margin: 0;
    padding-left: 10px !important;
  }

  li {
    line-height: 2;
    white-space: nowrap;
  }

  mark {
    background-color: $brand-color-dark;
    border-radius: 2.5px;
    color: $white;
    font-size: $paragraph-font-size-sm;
    font-weight: $font-weight-semi-bold;
    margin-right: 0.4rem;
    padding: 0 0.4rem;
  }
}

.introjs-dontShowAgain {
  & label {
    background-color: transparent !important;
    color: $black !important;
    font-size: $paragraph-font-size-sm !important;
  }
}

.introjs-tooltip {
  background-color: $gray-lightest !important;
  border-radius: 18px;
  box-shadow: $modal-content-border-color !important;
  color: $white !important;
  font-weight: $font-weight-normal !important;
  max-width: max-content !important;
}

.gdx-search-intro {
  &.introjs-tooltip {
    min-width: max-content;
  }
}

.introjs-tooltip-title {
  @extend %heading-title;
}

.introjs-tooltipReferenceLayer * {
  font-family: $font-family-work-sans !important;
}

.introjs-tooltiptext {
  color: $black !important;
  font-size: $paragraph-font-size !important;
  padding: 10px 20px !important;
  transition: color .1s ease-in;

  h1 {
    @extend %heading-title;
  }

  p {
    font-size: $paragraph-font-size-sm !important;
    margin: 0 !important;
    padding-bottom: 5px !important;
  }
}

.introjs-skipbutton {
  display: none;
}

#intro-button {
  flex-grow: 1;
}

.introjs-nextbutton {
  float: left !important;

  &:hover {
    background-color: $orange;
    color: $white;
  }
}

.introjs-prevbutton {
  background-color: $white;
  border-color: $gray-lighter;
  color: $black;
  float: right !important;

  &:hover {
    background-color: $white;
    color: $black;
  }
}

.introjs-arrow {
  border: 10px solid transparent !important;

  &.right,
  &.right-bottom,
  &.right-top {
    border-left-color: $gray-lightest !important;
    right: -19px;
    top: 14px;
  }

  &.left,
  &.left-bottom,
  &.left-top {
    border-right-color: $gray-lightest !important;
    left: -20px !important;
    top: 20px !important;
  }

  &.bottom,
  &.bottom-right,
  &.bottom-left {
    border-top-color: $gray-lightest !important;
  }

  &.top,
  &.top-right,
  &.top-middle,
  &.top-left {
    border-bottom-color: $gray-lightest !important;
    left: 40px !important;
    top: -20px !important;
  }
}

.introjs-tooltipbuttons {
  border-top: 0 !important;
  display: flex !important;
  padding: 20px !important;
}

.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.9) 0 0 1px 2px, rgba(33, 33, 33, 0.8) 0 0 0 5000px !important;
  height: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 0 !important;
}

.free-text-tooltip {
  bottom: -20rem !important;
  box-shadow: 0 0 !important;

  .introjs-tooltiptext {
    @extend %listing
  }

  .introjs-tooltipbuttons {
    justify-content: flex-start !important;
    padding-top: 0 !important;

    .introjs-nextbutton {
      display: none;
    }

    button {
      margin-left: 0 !important;
    }
  }
}

.introjs-helpbutton {
  bottom: 90px !important;
  right: 25px !important;
}

.introjs-analyzedialog {
  bottom: 22rem !important;
  right: 64% !important;

  .introjs-arrow {
    bottom: 50% !important;
    left: 100% !important;
    transform: rotateZ(-90deg) !important;
  }
}

.introjs-optionbutton {
  left: -16.6px !important;
}

.introjs-tableviewbutton {
  left: -28px !important;
}

.introjs-landing-page {
  top: 11.2rem !important;
}

.introjs-landing-page-above {

  .introjs-arrow {
    bottom: -1.2rem;
    left: 3rem;
  }
}

.introjs-search {
  .introjs-tooltiptext {
    ul {
      list-style: none;
    }

    li {
      line-height: 2;

      &::before {
        color: $orange;
        content: '\25CF';
        display: inline-block;
        margin-left: -3em;
        width: 1em;
      }
    }
  }
}

.introjs-addtoshortlist {
  left: -175px !important;

  .introjs-arrow {
    left: 275px !important;
  }
}

.introjs-finish-tour,
.introjs-skip-tour {
  min-width: 450px !important;
  top: -3rem !important;

  .introjs-tooltip-title {
    color: $black !important;
    font-size: $paragraph-font-size !important;
    font-weight: $font-weight-medium !important;
  }

  .introjs-arrow {
    bottom: 5rem !important;
    top: 4rem !important;
  }
}
