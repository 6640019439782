// sass-lint:disable variable-name-format
// sass-lint:disable nesting-depth
// sass-lint:disable no-disallowed-properties
@import '../../../../polly-v2.mixins';

body {
  .p-overlaypanel {
    background-color: $overlayPanelBgColor;
    border: $overlayPanelBorder;
    box-shadow: $overlayPanelShadow;

    .p-overlaypanel-content {
      padding: $overlayPanelContainerPadding;
    }

    &:before,
    &:after {
      border-bottom-color: transparent;
      border-top-color: transparent;
      content: unset;
      left: unset;
      position: unset;
    }
  }

  //  this class is used for user action panel for sidebar.
  .shiny-desktop-menu,
  .ui-overlaypanel {
    .p-overlaypanel-content {
      padding: 0;
    }
  }

  .user-action-panel {
    border-radius: 0.5rem;

    .p-overlaypanel-content {
      padding: 0;
    }

    &__transform {
      transform: translate(4.2rem, 2.9rem) !important;
    }
  }

  .cta-panel {
    border-radius: 0.25rem;
    box-shadow: 0 125rem 0.25rem rgba(0, 0, 0, 0.25);
    margin: 0.3125rem;

    .p-overlaypanel-content {
      padding: 0;
    }
  }

  .alternate-overlay-1 {
    .p-overlaypanel-content {
      max-height: 40vh;
      max-width: 35vw;
      overflow-y: scroll;
    }
  }

  .workspace-context-menu {
    width: 9rem;
  }

  .prime-overlay-context-menu {
    background-color: $white;
    border-radius: 0.25rem;
    box-shadow: 0 4px 10px 0 $black-shadow;

    .p-overlaypanel {
      &::after,
      &::before {
        opacity: 0;
      }

      .p-overlaypanel-content {
        padding: 0;
      }
    }
  }

  .alternate-overlay-2 {
    .p-overlaypanel-content {
      max-width: 11rem;
      padding: 0;
    }
  }

  .user-help-panel {
    left: 0 !important;

    .p-overlaypanel-content {
      padding: 0;
    }
  }

  .workspace-search-info-overlay {
    @include add-color(background-color, $brand-color-dark);
    border: 0;
    border-radius: 0.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

    .p-overlaypanel-content {
      line-height: 2;
      padding: .625rem 1rem .625rem 0;

      .tooltip-header {
        @include add-color(color, $white);
        padding: 0 .625rem;

        h1 {
          margin: 0.625rem;
        }
      }

      .tooltip-content {
        .search-info-list {
          margin: 0;
          padding: 0 2rem;

          p {
            font-size: $paragraph-font-size-sm;
            margin: 0;
          }
        }
      }
    }

    mark {
      @include add-color(background-color, $white);
      @include add-color(color, $brand-color-dark);
      border-radius: 2.5px;
      font-size: $paragraph-font-size-sm;
      font-weight: $font-weight-semi-bold;
      margin: 0.2rem;
      padding: 0 0.4rem;
    }
  }

}
