@import 'node_modules/polly-qubit/scss/polly-v2.mixins';
@import 'node_modules/polly-qubit/scss/variables';

.notebook-preview-container {
  .nb-notebook {
    line-height: 1.5;
    margin: 0 auto;
    max-width: 85%;

    blockquote {
      border-left: 5px solid color-mix(in srgb, $primary-neutral 20%, $white 80%);
      margin-left: 0;
      padding-left: 1em;
    }
  }

  .nb-stdout,
  .nb-stderr {
    margin: 1em 0;
    padding: 0.1em 0.5em;
    white-space: pre-wrap;
  }

  .nb-stderr {
    @include add-color(background-color, $primary-orange);
  }

  .nb-cell+.nb-cell {
    margin-top: 0.5em;
  }

  .nb-output {
    border-right: 1px dotted color-mix(in srgb, $primary-neutral 20%, $white 80%);
    min-height: 1em;
    overflow-x: scroll;
    width: 100%;

    table {
      border: 1px solid $black;
      border-collapse: collapse;
    }

    th {
      font-weight: bold;
    }

    th,
    td {
      border: 1px solid $black;
      border-collapse: collapse;
      padding: 0.25em;
      text-align: left;
      vertical-align: middle;
    }

    img {
      max-width: 100%;
    }

    &::before {
      content: 'Out [' attr(data-prompt-number) ']:';
    }
  }

  .nb-cell {
    position: relative;
  }

  .nb-raw-cell {
    @include add-color-mix(background-color, $primary-neutral 20%, $white 80%);
    font-family: Consolas, Monaco, 'Andale Mono', monospace;
    margin: 0.5em 0;
    padding: 1em;
    white-space: pre-wrap;
  }

  .nb-input {
    @include add-color(background-color, $primary-neutral-40);
    border-radius: 0.2rem;

    &::before {
      content: 'In [' attr(data-prompt-number) ']:';
    }
  }

  .nb-output,
  .nb-input {
    &::before {
      @include add-color(background-color, $white);
      @include add-color-mix(color, $primary-neutral 80%, $black 20%);
      font-family: monospace;
      left: -5em;
      position: absolute;
    }
  }

  .nb-markdown-cell,
  .nb-output {
    font-family: $inter;
  }
}
