/*---------------------------------------
  global mixins
---------------------------------------*/

@mixin fontConfig($fontFamily, $fontSize, $fontColor, $fontLineHeight, $fontLetterSpacing, $fontWeight) {
    font-family: $fontFamily;
    color: $fontColor;
    font-size: $fontSize;
    line-height: $fontLineHeight;
    letter-spacing: $fontLetterSpacing;
    font-weight: $fontWeight;
}

@mixin add-color($property-name, $color) {
  #{$property-name}: $color;
}
