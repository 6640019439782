// sass-lint:disable variable-name-format
// sass-lint:disable max-line-length
// sass-lint:disable max-file-line-count

//main color scheme
$primaryColor: $brand-color;
$primaryLightColor: $brand-color-light;
$primaryDarkColor: $brand-color-dark;
$primaryDarkerColor: $brand-color-dark;
$primaryTextColor: $white;
$alternateColor: $brand-color-alternate;

// font weights
$fontWeightSemiBold: 500;

//Global
$fontFamily: $font-family-base;
$fontSize: 14px;
$textColor: $black;
$textSecondaryColor: lighten($textColor, 25%);
$borderRadius: 3px;
$transitionDuration: 0.3s;

// Font size
$fontSizeSmall: 12px;

//anchors
$linkColor: $primaryColor;
$linkHoverColor: $primaryDarkColor;
$linkActiveColor: $primaryDarkerColor;

//highlight
$highlightBgColor: $primaryColor;
$highlightColorText: $primaryTextColor;

//input field
$inputPadding: 0.429em;
$inputBgColor: $white;
$inputBorderRadius: $borderRadius + 2;
$inputBorder: 2px solid $gray-border;
$inputHoverBorderColor: $brand-color-dark;
$inputFocusBorderColor: $primaryColor;
$inputFocusShadow: none;
$inputErrorBorderColor: $red;
$inputErrorColor: $red;
$inputErrorPlaceholderColor: $red;
$inputFontSize: $fontSizeSmall;
$inputFontWeight: $font-weight-normal;
$inputFontFamily: $font-family-base;
$inputTextColor: $gray-text;
$inputTransition: border-color $transitionDuration;
$dropdownBorder: 1px solid $gray-light;
$dropdownBorderRadius: 6px;
$dropdownPadding: 0.125em;

//alternate input text 1
$inputLargeFontSize: $fontSize;
$inputLargeHeight: 3rem;

//groups
$inputGroupMinWidth: 2em;
$inputGroupPadding: 0.2em;
$inputGroupBorder: 2px solid $gray-border;
$inputGroupBgColor: $white;
$inputGroupTextColor: $gray-text;
$inputGroupHeight: $input-height;
$inputGroupBorderRadius: 3px;
$inputGroupHoverBorderColor: $brand-color-dark;
$inputGroupFocusBorderColor: $primaryColor;

//input lists
$inputListMinWidth: 12em;
$inputListBgColor: $white;
$inputListPadding: 0;
$inputListItemPadding: 0.429em 0.857em;
$inputListItemBgColor: transparent;
$inputListItemTextColor: $black;
$inputListItemHoverBgColor: $white;
$inputListItemHoverTextColor: $black;
$inputListItemHighlightBgColor: $highlightBgColor;
$inputListItemHighlightTextColor: $highlightColorText;
$inputListItemBorder: 0 none;
$inputListItemMargin: 0;
$inputListItemDividerColor: $white;
$inputListHeaderPaddingTop: 0.429em;
$inputListHeaderPaddingLeft: 0.857em;
$inputListHeaderPaddingRight: 0.857em;
$inputListHeaderPaddingBottom: 0.429em;
$inputListHeaderMargin: 0;
$inputListHeaderBgColor: $white;
$inputListHeaderTextColor: $textColor;
$inputListHeaderBorder: 1px solid $white;
$inputListHeaderSearchIconColor: $primaryColor;
$inputListHeaderCloseIconColor: $textColor;
$inputListHeaderCloseIconHoverColor: $primaryColor;
$inputListHeaderCloseIconTransition: color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding: 12px;
$inputContentPanelBgColor: $white;
$inputContentPanelTextColor: $textColor;

//inputs with overlays
$inputOverlayBorder: 1px solid $white;
$inputOverlayShadow: 0 3px 6px 0 $black;

//input dropdowns
$inputDropdownBorderRadius: $borderRadius + 2;
$inputDropdownBorder: 2px solid $gray-border;
$inputDropdownHeight: 1.75rem;
$inputDropdownIconColor: $gray-text;
$inputDropdownFontWeight: $font-weight-normal;
$inputDropdownSelectBgColor: $white;
$inputDropdownTextColor: $gray-text;
$inputDropdownHoverBorderColor: $brand-color-dark;
$inputDropdownTriggerTextColor: $primaryColor;
$inputDropdownPanelBorder: 1px solid $primaryColor;
$inputDropdownPanelShadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
$inputDropdownBgColor: $white;
$inputDropdownFilterIconColor: $primaryColor;
$inputDropdownHoverBgColor: $brand-color-lightest;
$inputDropdownFontFamily: $font-family-base;
$inputDropdownFontSize: $fontSizeSmall;
$inputDropdownGroupLabelFontWeight: $font-weight-semi-bold;
$inputDropdownLabelHeight: 0.75rem;
$inputDropdownLabelLineHeight: 0.75rem;
$inputDropdownLabelPadding: 0 0 0 0.3rem;
$inputDropdownShadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.4);
$inputDropdownItemPadding: 0.35rem 1.5rem 0.3rem 0.5rem;
$inputDropdownLabelFontSize: 0.75rem;
$inputDropdownGroupItemPadding: 0.5rem 0.6rem 0.25rem 0.6rem;
$inputDropdownGroupFontWeight: $font-weight-bold;

// multiselect
$uimsBgColor: $white;
$uimsBorderRadius: $borderRadius + 2;
$uimsFontWeight: $font-weight-normal;
$uimsTextColor: $gray-text;
$uimsPlaceholderTextColor: $gray-lighter;
$uimsBorder: 2px solid $gray-border;
$uimsTransition: border-color 0.2s, box-shadow 0.2s;
$uimsHoverBorderColor: $brand-color-dark;
$uimsBoxShadow: $gray-dark;
$uimsFocusBorderColor: $brand-color-dark;
$uimsTriggerBgColor: transparent;
$uimsTriggerColor: $gray;
$uimsPanelBorder: 1px solid $primaryColor;
$uimsPanelBgColor: $white;
$uimsHoverBgColor: $brand-color-lightest;
$uimsPanelShadow: $gray-dark;
$uimsCheckBoxDisableBorder: 1px solid $gray-lighter;
$uimsCheckBoxDisableHoverBorder: $primaryColor;
$uimsItemFocusShadow: none;
$uimsCloseFocusShadow: none;
$uimsFilterIconColor: $primaryColor;
$uimsFontFamily: $font-family-base;
$uimsFontSize: $fontSizeSmall;
$uimsGroupLabelFontWeight: $font-weight-semi-bold;

//button
$buttonTextOnlyPadding: 0.429em 1em;
$buttonWithLeftIconPadding: 0.429em 1em 0.429em 2em;
$buttonWithRightIconPadding: 0.429em 2em 0.429em 1em;
$buttonIconOnlyPadding: 0.429em;
$buttonIconOnlyWidth: 2.143em;
$buttonBgColor: $primaryColor;
$buttonBorder: 1px solid $primaryColor;
$buttonTextColor: $primaryTextColor;
$buttonTextFontFamily: $font-family-base;
$buttonTextFontWeight: $btn-font-weight;
$buttonHoverBgColor: $primaryDarkColor;
$buttonHoverTextColor: $primaryTextColor;
$buttonHoverBorderColor: $primaryDarkColor;
$buttonActiveBgColor: $primaryDarkerColor;
$buttonActiveTextColor: $primaryTextColor;
$buttonActiveBorderColor: $primaryDarkerColor;
$buttonFocusBorderColor: $primaryDarkerColor;
$buttonFocusOutline: 0 none;
$buttonFocusOutlineOffset: 0;
$buttonTransition: background-color $transitionDuration border-color $transitionDuration;
$raisedButtonShadow: 0 2px 3px 0 rgba($black, 0.15);
$roundedButtonBorderRadius: 15px;

$secondaryButtonColor: $white;
$secondaryButtonTextColor: $black;

$infoButtonColor: $blue;
$infoButtonTextColor: $white;

$successButtonColor: $green;
$successButtonTextColor: $white;

$warningButtonColor: $yellow;
$warningButtonTextColor: $black;

$dangerButtonColor: $red;
$dangerButtonTextColor: $white;

// date-picker
$datePickerFontFamily: $fontFamily;
$datePickerFontSize: $fontSizeSmall;
$datePickerPadding: 0.875rem;
$datePickerMarginTop: 0.5rem;
$datePickerBgColor: $white;
$datePickerColor: $gray-text;
$datePickerBorder: 1px solid $gray-light;
$datePickerBorderRadius: 3px;
$datePickerMonthMarginRight: 0.5rem;
$datePickerTableMargin: 0.875rem 0;
$datePickerTableHeaderPadding: 0.5rem;
$datePickerTableHeaderWidth: 1rem;
$datePickerTableHeaderHeight: 1rem;
$datePickerTableDataPadding: 0.5rem;
$datePickerTableDataWidth: 1rem;
$datePickerTableDataHeight: 1rem;
$datePickerTableDataBorder: 1px solid transparent;
$datePickerTableDataBorderRadius: 3px;
$datePickerTableDataHighlightColor: $white;
$datePickerTableDataBgColor: $primaryColor;
$datepickerDisabledOpacity: 0.4;

//checkbox
$checkboxWidth: 0.875rem;
$checkboxHeight: 0.875rem;
$checkboxFillColor: $gray-text;
$checkboxActiveBorderColor: $alternateColor;
$checkboxActiveBgColor: $alternateColor;
$checkboxActiveTextColor: $checkboxFillColor;
$checkboxActiveHoverBgColor: $alternateColor;
$checkboxActiveHoverTextColor: $checkboxFillColor;
$checkboxActiveHoverBorderColor: $alternateColor;
$checkboxActiveFocusBgColor: $alternateColor;
$checkboxActiveFocusTextColor: $checkboxFillColor;
$checkboxActiveFocusBorderColor: $primaryDarkerColor;
$checkboxFocusBgColor: $inputBgColor;
$checkboxFocusTextColor: $primaryColor;
$checkboxFocusBorderColor: $primaryColor;
$checkboxTransition: background-color $transitionDuration, border-color $transitionDuration;
$checkboxFocusShadow: none;
$checkboxIconSize: $font-size-xs;
$checkboxSmallFont: $font-size-xs;
$checkboxFontWeight: $fontWeightSemiBold;
$checkboxFontFamily: $fontFamily;

//radiobutton
$radiobuttonWidth: 0.875rem;
$radiobuttonHeight: 0.875rem;
$radiobuttonFillColor: $gray-text;
$radiobuttonActiveBorderColor: $alternateColor;
$radiobuttonActiveBgColor: $alternateColor;
$radiobuttonActiveTextColor: $radiobuttonFillColor;
$radiobuttonActiveHoverBgColor: $alternateColor;
$radiobuttonActiveHoverBorderColor: $alternateColor;
$radiobuttonActiveHoverTextColor: $radiobuttonFillColor;
$radiobuttonActiveFocusBgColor: $alternateColor;
$radiobuttonActiveFocusTextColor: $radiobuttonFillColor;
$radiobuttonActiveFocusBorderColor: $primaryDarkerColor;
$radiobuttonFocusBgColor: $inputBgColor;
$radiobuttonFocusTextColor: $primaryColor;
$radiobuttonFocusBorderColor: $primaryColor;
$radiobuttonIconBgColor: $radiobuttonFillColor;
$radiobuttonTransition: background-color $transitionDuration, border-color $transitionDuration;
$radiobuttonFontSize: $font-size-sm;
$radiobuttonSmallFont: $font-size-xs;
$radiobuttonFontWeight: 500;
$radiobuttonFocusShadow: none;
$radiobuttonFontFamily: $fontFamily;
$radiobuttonIconSize: 0.5rem;

//togglebutton
$toggleButtonBgColor: $white;
$toggleButtonBorder: 1px solid $white;
$toggleButtonTextColor: $black;
$toggleButtonIconColor: $gray;
$toggleButtonHoverBgColor: $white;
$toggleButtonHoverBorderColor: $white;
$toggleButtonHoverTextColor: $black;
$toggleButtonHoverIconColor: $black;
$toggleButtonActiveBgColor: $primaryColor;
$toggleButtonActiveBorderColor: $primaryColor;
$toggleButtonActiveTextColor: $primaryTextColor;
$toggleButtonActiveIconColor: $primaryTextColor;
$toggleButtonActiveHoverBgColor: $primaryDarkColor;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;
$toggleButtonActiveHoverTextColor: $primaryTextColor;
$toggleButtonActiveHoverIconColor: $primaryTextColor;
$toggleButtonFocusOutline: 0 none;
$toggleButtonFocusBgColor: $white;
$toggleButtonFocusBorderColor: $white;
$toggleButtonFocusTextColor: $black;
$toggleButtonFocusIconColor: $black;
$toggleButtonActiveFocusBgColor: $primaryDarkerColor;
$toggleButtonActiveFocusBorderColor: $primaryDarkerColor;
$toggleButtonActiveFocusTextColor: $primaryTextColor;
$toggleButtonActiveFocusIconColor: $primaryTextColor;

//inplace
$inplacePadding: $inputPadding;
$inplaceHoverBgColor: $white;
$inplaceHoverTextColor: $textColor;
$inplaceTransition: background-color $transitionDuration;

//rating
$ratingTransition: color $transitionDuration;
$ratingCancelIconColor: $red;
$ratingCancelHoverIconColor: darken($red, 50);
$ratingIconFontSize: 20px;
$ratingStarIconColor: $textColor;
$ratingStarIconHoverColor: $primaryColor;

//slider
$sliderBgColor: $disabled-shape-color;
$sliderBorder: 0 none;
$sliderHeight: 0.286em;
$sliderWidth: 0.286em;
$sliderHandleWidth: 1.125rem;
$sliderHandleHeight: 1.125rem;
$sliderHandleBgColor: $alternateColor;
$sliderHandleBorder: 2px solid $alternateColor;
$sliderHandleBorderRadius: 100%;
$sliderHandleFocusBorder: 0.25rem solid $alternateColor;
$sliderHandleFocusBgColor: $white;
$sliderHandleHoverBorder: 0.25rem solid $alternateColor;
$sliderHandleHoverBgColor: $white;
$sliderHandleTransition: border-color $transitionDuration;
$sliderRangeBgColor: $brand-color-dark;
$sliderDisabledOpacity: 0.4;

//calendar
$calendarWidth: 20em;
$calendarNavIconColor: $white;
$calendarNavIconHoverColor: $primaryColor;
$calendarNavIconTransition: color $transitionDuration;
$calendarPadding: 0.857em;
$calendarTableMargin: 0.857em 0 0 0;
$calendarCellPadding: 0.286em;
$calendarCellDatePadding: 0.286em;
$calendarCellDateWidth: 1.714em;
$calendarCellDateHeight: 1.714em;
$calendarCellDateHoverBgColor: $white;
$calendarCellDateBorderRadius: $borderRadius;
$calendarCellDateSelectedBgColor: $primaryColor;
$calendarCellDateSelectedTextColor: $primaryTextColor;
$calendarCellDateTodayBgColor: $white;
$calendarCellDateTodayTextColor: $textColor;
$calendarTimePickerDivider: 1px solid $white;
$calendarTimePickerPadding: 0.857em;
$calendarTimePickerIconColor: $textColor;
$calendarTimePickerIconFontSize: 1.286em;
$calendarTimePickerTimeFontSize: 1.286em;
$calendarTimePickerIconHoverColor: $primaryColor;

//spinner
$spinnerButtonWidth: 2em;

//input switch
$inputSwitchBorderRadius: 30px;
$inputSwitchBgColor: $primaryTextColor;
$inputSwitchHoverBgColor: $primaryTextColor;
$inputSwitchOffHandleBgColor: $inputSwitchHoverBgColor;
$inputSwitchOffHandleIconBgColor: $primaryDarkColor;
$inputSwitchOffHandleHoverBgColor: $inputSwitchBgColor;
$inputSwitchOffHandleFocusBgColor: $inputSwitchBgColor;
$inputSwitchOnBorderColor: $primaryDarkColor;
$inputSwitchOnBgColor: $primaryDarkColor;
$inputSwitchOnHandleBgColor: $primaryDarkColor;
$inputSwitchOnHandleIconColor: $primaryTextColor;
$inputSwitchOnHandleFocusBgColor: $primaryDarkColor;
$inputSwitchOnHandleHoverBgColor: $primaryDarkerColor;
$inputSwitchTransition: border-color $transitionDuration;
$inputSwitchHandleTransition: background-color $transitionDuration;
$inputSwitchFocusShadow: none;

//panel common
$panelHeaderBorder: 1px solid $white;
$panelHeaderBgColor: $white;
$panelHeaderTextColor: $black;
$panelHeaderIconColor: $gray;
$panelHeaderIconHoverColor: $black;
$panelHeaderIconTransition: color $transitionDuration;
$panelHeaderFontWeight: 700;
$panelHeaderPadding: 0.571em 1em;
$panelContentBorder: 1px solid $white;
$panelContentBgColor: $white;
$panelContentTextColor: $textColor;
$panelContentPadding: 0.571em 1em;
$panelFooterBorder: 1px solid $white;
$panelFooterBgColor: $white;
$panelFooterTextColor: $textColor;
$panelFooterPadding: 0.571em 1em;
$panelHeaderHoverBgColor: $gray-lighter;
$panelHeaderHoverBorder: 1px solid $gray-lighter;
$panelHeaderHoverTextColor: $black;
$panelHeaderHoverIconColor: $black;
$panelHeaderActiveBgColor: $primaryColor;
$panelHeaderActiveBorder: 1px solid $primaryColor;
$panelHeaderActiveTextColor: $white;
$panelHeaderActiveIconColor: $white;
$panelHeaderActiveHoverBgColor: $primaryDarkerColor;
$panelHeaderActiveHoverBorder: 1px solid $primaryDarkerColor;
$panelHeaderActiveHoverTextColor: $white;
$panelHeaderActiveHoverIconColor: $white;

//accordion
$accordionSpacing: 2px;

//tabview
$tabsNavFontSize: $font-size-xs;
$tabsNavBorder: 1px solid $white;
$tabsNavBgColor: transparent;
$tabHeaderSpacing: 2px;
$tabHeaderColor: $grey-shapes;
$tabHeaderFontWeight: $font-weight-semi-bold;
$tabViewPanelBackgroundColor: $brand-color-light;
$tabViewPanelBorder: 1px solid $disabled-shape-color;
$tabViewTabBottomBorder: 4px solid transparent;
$tabViewActiveTabBottomBorder: 4px solid $brand-color-alternate;
$tabViewActiveHeaderColor: $text-color;

//scrollpanel
$scrollPanelHandleBgColor: $white;
$scrollPanelTrackBorder: 0 none;
$scrollPanelTrackBgColor: $white;

//paginator
$paginatorBgColor: $white;
$paginatorBorder: 1px solid $white;
$paginatorPadding: 0;
$paginatorIconColor: $textSecondaryColor;
$paginatorElementWidth: 2.286em;
$paginatorElementHeight: 2.286em;
$paginatorElementHoverBgColor: $white;
$paginatorElementHoverIconColor: $black;
$paginatorElementMargin: 0;
$paginatorElementPadding: 0;
$paginatorElementBorder: 0 none;
// paginator v14
$paginatorBg: $white;
$paginatorTextColor: $textColor !default;
$paginatorBorderWidth: 1px !default;
$paginatorElementBg: transparent !default;
$paginatorElementHoverBg: #e0e0e0 !default;
$paginatorElementIconColor: #717171 !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: #4c4c4c !default;
$paginatorElementBorderRadius: 50%;
$listItemTransition: box-shadow $transitionDuration !default;

//table
$tableHeaderCellPadding: 0.571em 0.857em;
$tableHeaderCellBgColor: $white;
$tableHeaderCellTextColor: $textColor;
$tableHeaderCellFontWeight: 700;
$tableHeaderCellBorder: 1px solid $white;
$tableHeaderCellHoverBgColor: $white;
$tableHeaderCellHoverTextColor: $black;
$tableBodyRowBgColor: $white;
$tableBodyRowTextColor: $textColor;
$tableBodyRowEvenBgColor: $white;
$tableBodyRowHoverBgColor: $white;
$tableBodyRowHoverTextColor: $black;
$tableBodyCellBorder: 1px solid $white;
$tableBodyCellPadding: 0.571em 0.857em;
$tableFooterCellPadding: 0.571em 0.857em;
$tableFooterCellBgColor: $white;
$tableFooterCellTextColor: $textColor;
$tableFooterCellFontWeight: 700;
$tableFooterCellBorder: 1px solid $white;
$tableResizerHelperBgColor: $primaryColor;
// alternate-table-2
$tableAlt2FontFamily: $headings-font-family;
$tableAlt2LineHeight: 1.333rem;
$tableAlt2BgColor: $white;
$tableAlt2Color: $text-color;
$tableAlt2FontSize: $fontSizeSmall;
$tableAlt2SortableColBgColor: $gray-lighter;
$tableAlt2SortableColColor: $gray-text;
$tableAlt2PageHighlightColor: $primaryColor;
// table-v2
$tableV2HeaderCellBgColor: $primaryLightColor;
$tableV2HeaderCellTextColor: $gray-light;
$tableV2HeaderCellFontWeight: $font-weight-medium;
$tableV2BodyCellBgColor: $white;
$tableV2BodyCellTextColor: $gray-light;

//schedule
$scheduleEventBgColor: $primaryDarkColor;
$scheduleEventBorder: 1px solid $primaryDarkColor;
$scheduleEventTextColor: $primaryTextColor;

//tree
$treeNodePadding: 0.143em 0;
$treeNodeLabelPadding: 0.286em;
$treeNodeContentSpacing: 0.143em;

//lightbox
$lightBoxNavIconFontSize: 3em;
$lightBoxNavIconColor: $white;

//org chart
$organizationChartConnectorColor: $white;

//messages
$messagesMargin: 1em 0;
$messagesPadding: 1em;
$messagesIconFontSize: 1.714em;
$messageCloseIconFontSize: 1.5em;
$infoMessageBgColor: lighten($blue, 40);
$infoMessageBorder: 0 none;
$infoMessageTextColor: $black;
$infoMessageIconColor: $black;
$successMessageBgColor: lighten($green, 40);
$successMessageBorder: 0 none;
$successMessageTextColor: $black;
$successMessageIconColor: $black;
$warnMessageBgColor: lighten($yellow, 40);
$warnMessageBorder: 0 none;
$warnMessageTextColor: $black;
$warnMessageIconColor: $black;
$errorMessageBgColor: lighten($red, 40);
$errorMessageBorder: 0 none;
$errorMessageTextColor: $black;
$errorMessageIconColor: $black;

//message
$messagePadding: 0.5em;
$messageMargin: 0;

// toast
$toastBoxShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$toastMargin: 0 0 1em 0;
$toastInfoColor: $white;
$toastInfoBgColor: $blue;
$toastSuccessColor: $white;
$toastSuccessBgColor: $green;
$toastWarnColor: $black;
$toastWarnBgColor: $yellow;
$toastErrorColor: $white;
$toastErrorBgColor: $red;
$toastMessageTextMargin: 0 0 0 0.5rem;
$toastSummaryFontWeight: $font-weight-bold;
$toastDetailMargin: 0.5rem 0 0 0;
$toastPadding: 0.45rem;

//growl
$growlTopLocation: 120px;
$growlIconFontSize: 2.571em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin: 0 0 1em 0;
$growlPadding: 1em;
$growlShadow: 0 3px 6px 0 $black;
$growlOpacity: 0.9;

//overlays
$overlayContentBorder: 1px solid $white;
$overlayContentBorder: 1px solid $white;
$overlayContainerShadow: 0 0 6px 0 $black;

//dialog
$dialogContentBgColor: $white;
$dialogHeaderPadding: 1em;
$confirmDialogPadding: 1.5em;
$dialogTitleBarHeight: 2.5rem;
$dialogTitleBarPadding: 0.7rem;
$dialogContentPadding: 0.571rem 0.7rem;
$dialogFooterPadding: 0.571rem 0.7rem;
$dialogLetterSpacing: 0.5px;
$dialogTitleBarColor: $brand-color-highlight;
$dialogFontWeight: $font-weight-semi-bold;
$dialogBoxShadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

// confirm-dialog
$confirmDialogFontFamily: $fontFamily;
$confirmDialogFontSize: $fontSize;
$confirmDialogIconFontSize: 1.5em;

//overlay panel
$overlayPanelBgColor: $white;
$overlayPanelCloseIconBgColor: $primaryColor;
$overlayPanelCloseIconColor: $white;
$overlayPanelCloseIconHoverBgColor: $primaryDarkerColor;
$overlayPanelCloseIconHoverColor: $white;
$overlayPanelBorder: 1px solid $gray-border;
$overlayPanelShadow: 0 1px 3px 0 $grey-shapes;
$overlayPanelContainerPadding: 0.571em 1em;

// ui-widgets
$uiWidgetFontFamily: $fontFamily;
$uiWidgetOverlayBgColor: $black;

//tooltip
$tooltipBgColor: $white;
$tooltipBorderColor: $black;
$tooltipFontColor: $black;
$tooltipFontFamily: $fontFamily;
$tooltipFontSize: $fontSizeSmall;
$tooltipFontWeight: $fontWeightSemiBold;
$tooltipPadding: $inputPadding;
$tooltipTextColor: $white;
$tooltipShadow: 0 2px 4px rgba(0, 0, 0, 0.25);

//steps
$stepsItemBgColor: $white;
$stepsItemBorder: 1px solid $white;
$stepsItemNumberColor: $textColor;
$stepsItemTextColor: $textSecondaryColor;
$stepsItemWidth: 28px;
$stepsItemHeight: 28px;

//progressbar
$progressBarHeight: 24px;
$progressBarBorder: 0 none;
$progressBarLabelFontSize: 0;
$progressBarBgColorLight: $brand-color-light;
$progressBarBgColorLightest: $gray-lightest;
$progressBarBgColorLighter: $gray-lighter;
$progressBarValueBgColor: $primaryColor;
$progressBarValueBgColorDanger: $red;
$progressBarValueBgColorSuccess: $green;
$progressBarValueBgColorWarning: $yellow;
$progressBarValueBgColorProgress: $blue;
$progressBarValueBgColorRejected: $orange;

//progress-spinner
$progressSpinnerColor: darken($primaryDarkerColor, 10%);

//sidebar
$sidebarBorderColor: $white;
$sidebarBoxShadowColor: $black;
$sidebarColor: $black;
$sidebarCloseColor: $gray;

//skeleton
$skeletonBgColor: $brand-color;
$skeletonRotationColor: linear-gradient(90deg, $gray-lighter, $gray-lightest, $gray-lighter);

//menu
$tieredMenuBgColor: $white;
$tieredMenuPadding: 0.5rem;
$tieredMenuFontSize: $fontSizeSmall;
$tieredMenuFontFamily: $fontFamily;
$tieredMenuFontColor: $black;
$tieredMenuHoverBgColor: $gray-light;
$tieredMenuHoverFontWeight: $font-weight-semi-bold;
$tieredMenuBorder: 2px solid $gray-border;
$tieredMenuPanelShadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

//menu
$menuBgColor: $white;
$menuBorder: 1px solid $white;
$menuPadding: 0;
$menuTextColor: $textColor;
$menuitemPadding: 0.714em 0.857em;
$menuitemMargin: 0;
$menuitemTextColor: $black;
$menuitemIconColor: $black;
$menuitemHoverTextColor: $black;
$menuitemHoverIconColor: $black;
$menuitemHoverBgColor: $white;
$menuitemActiveTextColor: $black;
$menuitemActiveIconColor: $black;
$menuitemActiveBgColor: $white;
$menuitemDangerColor: $red;
$menuShadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.4);
$submenuHeaderMargin: 0;
$submenuPadding: 1px 0;
$overlayMenuBorder: 1px solid $white;
$overlayMenuShadow: 0 0 6px 0 $black;
$popupMenuTextColor: $text-color;
$popupMenuItemHoverBgColor: $primaryLightColor;

//misc
$black-shadow: rgba(0, 0, 0, 0.2);
$maskBgColor: rgba($black, 0.4);
$inlineSpacing: 0.5em;
$chipsItemMargin: 0 0.286em 0 0;
$dataIconColor: $textSecondaryColor;
$dataIconHoverColor: $textColor;

//general
$disabledOpacity: 0.5;
